import React from 'react';
import { MainRouter } from './routers';
import { MainThemeProvider } from './themes';

const App: React.FC = () => (
  <MainThemeProvider>
    <MainRouter />
  </MainThemeProvider>
);

export default App;
