import { Entities } from '@getvim/vim-connect';

export interface OnFhirAppFinishData {
  srn?: string;
  fhirApp: string;
  launchId: string;
}

export enum WidgetIncomingEventName {
  PatientInContext = 'PatientInContext',
  PatientOutOfContext = 'PatientOutOfContext',
  EncounterStart = 'EncounterStart',
  EncounterExit = 'EncounterExit',
  AppConfigChanged = 'AppConfigChanged',
  OnFhirAppFinish = 'OnFhirAppFinish',
}

export interface IncomingEvents {
  [WidgetIncomingEventName.PatientInContext]: Entities.Patient;
  [WidgetIncomingEventName.PatientOutOfContext]: undefined;
  [WidgetIncomingEventName.EncounterStart]: string;
  [WidgetIncomingEventName.EncounterExit]: undefined;
  [WidgetIncomingEventName.AppConfigChanged]: {
    accessToken: string;
    pubsubId: string;
    targetId: string;
  };
  [WidgetIncomingEventName.OnFhirAppFinish]: OnFhirAppFinishData;
}

export type EventPayload = {
  [Event in keyof IncomingEvents]: {
    event: Event;
    data: IncomingEvents[Event];
  };
}[keyof IncomingEvents];
