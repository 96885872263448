import { gql } from '@apollo/client';

export const LAUNCH_MUTATION = gql`
  mutation launchSMARTApp($input: LaunchSMARTAppInput!) {
    launchSMARTApp(input: $input) {
      smartAppUrl
    }
  }
`;

export const GET_AUTHORIZATION_URL_QUERY = gql`
  query getAuthorizationUrl($input: GetAuthorizationUrlInput!) {
    getAuthorizationUrl(input: $input)
  }
`;

export const GET_TOKEN_MUTATION = gql`
  mutation getToken($input: GetTokenInput!) {
    getToken(input: $input) {
      token
      patient
      encounter
      selectedNpi
      transactionId
    }
  }
`;

export const GET_RESOURCES_QUERY = gql`
  query getResources($token: String!) {
    getResources(token: $token) {
      patientId
      encounterId
      selectedNpi
      userMetadata {
        organizationName
        ehrSystem
        fullAddress
        zipCode
        userId
        npi
      }
    }
  }
`;

export const UPDATE_STATUS_MUTATION = gql`
  mutation updateStatus($input: UpdateStatusInput!) {
    updateStatus(input: $input) {
      success
    }
  }
`;
