import { Infra } from '@getvim/vim-connect';
import {
  GET_AUTHORIZATION_URL_QUERY,
  GET_RESOURCES_QUERY,
  GET_TOKEN_MUTATION,
  LAUNCH_MUTATION,
  UPDATE_STATUS_MUTATION,
} from './queries';
import {
  GetAuthorizationUrlInput,
  GetResourcesResponse,
  GetTokenInput,
  GetTokenResponse,
  LaunchSMARTAppInput,
  UpdateStatusInput,
  UpdateStatusResponse,
} from '../../types';
import { BaseInternalApi } from '../../common';

export class FhirSimulatorBffApi extends BaseInternalApi {
  constructor() {
    super('/api/graphql');
  }

  public async getLaunchUrl(
    input: LaunchSMARTAppInput,
  ): Promise<Infra.Common.Types.LaunchMetadata | undefined> {
    const client = await this.getClient();
    const { data } = await client.mutate<{ launchSMARTApp: Infra.Common.Types.LaunchMetadata }>({
      mutation: LAUNCH_MUTATION,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return data?.launchSMARTApp;
  }

  public async getAuthorizationUrl(input: GetAuthorizationUrlInput): Promise<string | undefined> {
    const client = await this.getClient();
    const { data } = await client.query<{ getAuthorizationUrl: string }>({
      query: GET_AUTHORIZATION_URL_QUERY,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return data?.getAuthorizationUrl;
  }

  public async getToken(input: GetTokenInput): Promise<GetTokenResponse | undefined> {
    const client = await this.getClient();
    const { data } = await client.mutate<{ getToken: GetTokenResponse }>({
      mutation: GET_TOKEN_MUTATION,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return data?.getToken;
  }

  public async getResources(token: string): Promise<GetResourcesResponse | undefined> {
    const client = await this.getClient();
    const { data } = await client.query<{ getResources: GetResourcesResponse }>({
      query: GET_RESOURCES_QUERY,
      variables: { token },
      fetchPolicy: 'no-cache',
    });
    return data?.getResources;
  }

  public async updateStatus(input: UpdateStatusInput): Promise<UpdateStatusResponse | undefined> {
    const client = await this.getClient();
    const { data } = await client.mutate<{ updateStatus: UpdateStatusResponse }>({
      mutation: UPDATE_STATUS_MUTATION,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return data?.updateStatus;
  }
}
