import React from 'react';
import { GetResourcesResponse, GetResourcesUserMetadata } from '../../../types';
import { DataTable } from '../../DataTable';

interface Props {
  ehrData?: Omit<GetResourcesResponse, 'userMetadata'>;
  user?: GetResourcesUserMetadata;
}

export const Resources: React.FC<Props> = ({ ehrData, user }: Props) => {
  return (
    <div>
      {ehrData && <DataTable title="EHR Resources" data={ehrData} />}
      {user && <DataTable title="User Metadata" data={{ ...user }} />}
    </div>
  );
};
