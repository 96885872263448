import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Buffer } from 'buffer';
import { GetTokenInput, SMARTApp } from '../../types';
import { internalApi } from '../../internal-api/fhir-simulator-bff';
import { appLogger, BaseInternalApi } from '../../common';

export const useAuthEndpoint = (): [string | undefined, boolean] => {
  const [token, setToken] = useState<string>();
  const [hasError, setHasError] = useState(false);
  const { search } = useLocation();

  const { jwtParam, configIdParam, accessTokenParam } = useMemo(() => {
    const params = new URLSearchParams(search);
    const state = params.get('state') ?? '';
    const decodedState = JSON.parse(Buffer.from(state, 'base64').toString());
    return {
      jwtParam: params.get('code'),
      configIdParam: decodedState?.configId,
      accessTokenParam: decodedState?.accessToken,
    };
  }, [search]);

  const getToken = useCallback(
    async (jwt: string, configId: string) => {
      const input: GetTokenInput = {
        smartApp: SMARTApp.fhirSimulator,
        configId,
        jwt,
      };

      if (accessTokenParam) {
        BaseInternalApi.setAccessToken(accessTokenParam);
      } else {
        appLogger.error('Failed to get fhir token, Missing access token!');
        setHasError(true);
        return;
      }

      try {
        const result = await internalApi.fhirSimulatorBff.getToken(input);
        appLogger.info('Got token successfully');

        if (result?.token) {
          setToken(result.token);
        } else {
          throw Error('Missing token on response');
        }
      } catch (error) {
        appLogger.error('Failed to get token', { input, error });
        setHasError(true);
      }
    },
    [accessTokenParam],
  );

  useEffect(() => {
    if (jwtParam && configIdParam) {
      getToken(jwtParam, configIdParam);
    }
  }, [configIdParam, jwtParam, getToken]);

  return [token, hasError];
};
