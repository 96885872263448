import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Main } from '../components';
import { FhirAppRouter } from './FhirAppRouter';
import { FHIR_APP_ROUTES } from '../consts';

export const MainRouter: React.FC = () => (
  <Router basename={import.meta.env.BASE_URL}>
    <Switch>
      <Route exact path="/">
        <Main />
      </Route>
      <Route path={FHIR_APP_ROUTES.BASE}>
        <FhirAppRouter />
      </Route>
    </Switch>
  </Router>
);
