import { Infra } from '@getvim/vim-connect';

export interface LaunchSMARTAppInput
  extends Omit<Infra.Common.Types.LaunchAppInput, 'smartApp' | 'deviceId'> {
  smartApp: SMARTApp;
}

export enum SMARTApp {
  fhirSimulator = 'fhirSimulator',
}
