import React from 'react';
import { Typography } from '@mui/material';
import { Redirect, useLocation } from 'react-router-dom';
import { FHIR_APP_ROUTES } from '../../consts';
import './SmartApp.less';
import { Actions } from './Actions';

interface LocationState {
  token?: string;
}

export const SmartApp: React.FC = () => {
  const { search, state }: { search: string; state: LocationState } = useLocation();

  if (!state?.token) {
    return <Redirect to={`${FHIR_APP_ROUTES.LOGIN}${search}`} />;
  }

  return (
    <div className="fhir-app-container">
      <Typography className="app-title" variant="h5">
        FHIR App
      </Typography>
      <Actions token={state.token} />
    </div>
  );
};
