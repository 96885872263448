import React from 'react';
import { TableContainer, Table, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import './DataTable.less';
import { useTheme } from '@mui/material/styles';

type Props = {
  data: Record<string, string>;
  title: string;
  className?: string;
};

export const DataTable: React.FC<Props> = ({ title, data, className }: Props) => {
  const theme = useTheme();

  return (
    <div
      className={`resources-table-container ${className} ${title
        .split(' ')
        .join('-')
        .toLowerCase()}`}
    >
      <Typography style={{ color: theme.palette.primary.main }} className="title">
        {title}
      </Typography>
      <TableContainer>
        <Table>
          <TableBody>
            {Object.keys(data).map((key) => (
              <TableRow key={key}>
                <TableCell className={`resource-key ${key}`}>{key}</TableCell>
                <TableCell className={`resource-value ${key}`}>{data[key]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
