import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { pink } from '@mui/material/colors';

const smartAppTheme = createTheme({
  palette: {
    primary: {
      main: pink[800],
    },
  },
});

export const SmartAppTheme: React.FC = ({ children }) => {
  return <ThemeProvider theme={smartAppTheme}>{children}</ThemeProvider>;
};
