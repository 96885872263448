import { VimConnectGqlClient } from '@getvim/components-vim-connect-gql-client';

export class BaseInternalApi {
  private gqlClient: VimConnectGqlClient | undefined;

  private static accessToken: string | null;

  static setAccessToken(accessToken: string): void {
    BaseInternalApi.accessToken = accessToken;
  }

  protected async getClient(): Promise<VimConnectGqlClient> {
    if (!this.gqlClient) {
      this.gqlClient = new VimConnectGqlClient({
        apiUrl: this.apiUrl,
        accessTokenCallback: () => BaseInternalApi.accessToken,
      });
    }

    return this.gqlClient;
  }

  constructor(private apiUrl: string) {}
}
