import React from 'react';
import { Typography } from '@mui/material';
import { useLoginRedirect } from '../../../hooks';

export const Login: React.FC = () => {
  const [hasError] = useLoginRedirect();
  return hasError ? (
    <Typography>Failed to redirect, please try again</Typography>
  ) : (
    <Typography>Redirecting...</Typography>
  );
};
