import React, { useCallback, useEffect, useState } from 'react';
import { Infra } from '@getvim/vim-connect';
import { Button } from '@mui/material';
import { useEvents } from '../../hooks';
import './Main.less';
import { ContextForm } from './ContextForm';
import { internalApi } from '../../internal-api/fhir-simulator-bff';
import { OnFhirAppFinishData, SMARTApp } from '../../types';
import { appLogger, BaseInternalApi } from '../../common';
import { DataTable } from '../DataTable';

export const Main: React.FC = () => {
  const {
    patient,
    encounterId,
    accessToken,
    pubsubId,
    targetId,
    auditEventData: lastAuditData,
  } = useEvents();
  const [launchUrl, setLaunchUrl] = useState<URL>();
  const [loading, setLoading] = useState<boolean>(false);
  const [auditData, setAuditData] = useState<OnFhirAppFinishData>();

  useEffect(() => {
    if (accessToken) {
      BaseInternalApi.setAccessToken(accessToken);
    }
  }, [accessToken]);

  useEffect(() => {
    if (lastAuditData?.launchId) {
      setAuditData(lastAuditData);
    }
  }, [lastAuditData]);

  const onReset = useCallback(() => {
    setLaunchUrl(undefined);
    setAuditData(undefined);
  }, []);

  const onLaunch = useCallback(
    async (context: Infra.Common.Types.InitialLaunchContext) => {
      if (!pubsubId || !targetId) {
        appLogger.error('Missing pubsubId/targetId for launch request', { pubsubId, targetId });
        return;
      }

      const input = {
        initialLaunchContext: context,
        pubsubId,
        targetId,
        smartApp: SMARTApp.fhirSimulator,
      };

      try {
        setLoading(true);
        const result = await internalApi.fhirSimulatorBff.getLaunchUrl(input);
        appLogger.info('Got launch url', { result, input });

        if (!result?.smartAppUrl) {
          appLogger.error('Got an empty launch url');
          return;
        }

        setLaunchUrl(result.smartAppUrl);
      } catch (error) {
        appLogger.error('Failed to getLaunchUrl', { input, error });
      } finally {
        setLoading(false);
      }
    },
    [pubsubId, targetId],
  );

  return (
    <div className="container">
      {launchUrl ? (
        <>
          <iframe title="launched-widget" src={launchUrl.toString()} className="iframe-wrapper" />
          <Button className="reset-button" variant="contained" onClick={onReset}>
            Reset
          </Button>
          {auditData?.launchId && (
            <DataTable
              className="audit-event-data"
              title="Got fhirAppFinish Event"
              data={{ ...auditData }}
            />
          )}
        </>
      ) : (
        <ContextForm
          patientId={patient?.patientId}
          encounterId={encounterId}
          onLaunch={onLaunch}
          loading={loading}
        />
      )}
    </div>
  );
};
