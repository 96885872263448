import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SmartApp, Authorize, Login } from '../components';
import { FHIR_APP_ROUTES } from '../consts';
import { SmartAppTheme } from '../themes';

export const FhirAppRouter: React.FC = () => {
  return (
    <SmartAppTheme>
      <Switch>
        <Route path={FHIR_APP_ROUTES.LOGIN}>
          <Login />
        </Route>
        <Route path={FHIR_APP_ROUTES.AUTHORIZE}>
          <Authorize />
        </Route>
        <Route exact path={FHIR_APP_ROUTES.MAIN}>
          <SmartApp />
        </Route>
      </Switch>
    </SmartAppTheme>
  );
};
