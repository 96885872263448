import React, { useState, useCallback } from 'react';
import { Button, Checkbox, FormControlLabel, Typography, TextField } from '@mui/material';
import { omit } from 'lodash';
import { GetResourcesResponse, GetResourcesUserMetadata } from '../../../types';
import { internalApi } from '../../../internal-api/fhir-simulator-bff';
import { appLogger } from '../../../common';
import { Resources } from '../Resources';
import './Actions.less';

interface Props {
  token: string;
}

export const Actions: React.FC<Props> = ({ token }: Props) => {
  const [ehrData, setEhrData] = useState<Omit<GetResourcesResponse, 'userMetadata'>>();
  const [user, setUser] = useState<GetResourcesUserMetadata>();

  const [auditEventSuccess, setAuditEventSuccess] = useState(true);
  const [auditEventSrn, setAuditEventSrn] = useState<string>();

  const fetchResources = useCallback(async () => {
    try {
      const payload = await internalApi.fhirSimulatorBff.getResources(token);
      appLogger.info('Fetched resources', payload);

      if (payload) {
        const { userMetadata, ...ehrResources } = payload;
        setEhrData(omit(ehrResources, '__typename'));
        setUser(omit(userMetadata, '__typename'));
      }
    } catch (error) {
      appLogger.error('Failed to fetch resources', { error });
    }
  }, [token]);

  const sendAuditEvent = useCallback(async () => {
    try {
      const payload = await internalApi.fhirSimulatorBff.updateStatus({
        token,
        srn: auditEventSrn,
        success: auditEventSuccess,
      });
      appLogger.info('Got response from audit event', payload);
    } catch (error) {
      appLogger.error('Failed to send audit event', { error });
    }
  }, [token, auditEventSrn, auditEventSuccess]);

  return (
    <div className="actions-container">
      <Button id="get-resources" variant="contained" onClick={fetchResources}>
        Get Resources
      </Button>
      <Button id="send-audit-event" variant="contained" onClick={sendAuditEvent}>
        Send Audit Event
      </Button>
      <div className="audit-event-details-container">
        <Typography className="audit-details-title">Audit event details</Typography>
        <FormControlLabel
          label="Success"
          control={
            <Checkbox
              checked={auditEventSuccess}
              onChange={(event) => setAuditEventSuccess(event.target.checked)}
            />
          }
        />
        <TextField
          id="srn"
          label="SRN"
          variant="outlined"
          size="small"
          margin="dense"
          value={auditEventSrn ?? ''}
          onChange={(event) => setAuditEventSrn(event.target.value)}
        />
      </div>
      <Resources ehrData={ehrData} user={user} />
    </div>
  );
};
