import React from 'react';
import { Redirect } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useAuthEndpoint } from '../../../hooks';
import { FHIR_APP_ROUTES } from '../../../consts';

export const Authorize: React.FC = () => {
  const [token, hasError] = useAuthEndpoint();

  if (token) {
    return <Redirect to={{ pathname: FHIR_APP_ROUTES.MAIN, state: { token } }} />;
  }

  return hasError ? (
    <Typography>Failed to fetch token, please try again</Typography>
  ) : (
    <Typography>Fetching Token...</Typography>
  );
};
