import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { teal } from '@mui/material/colors';

const mainTheme = createTheme({
  palette: {
    primary: {
      main: teal[800],
    },
  },
});

export const MainThemeProvider: React.FC = ({ children }) => {
  return <ThemeProvider theme={mainTheme}>{children}</ThemeProvider>;
};
