import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Typography,
  Button,
} from '@mui/material';
import './ContextForm.less';
import { Infra } from '@getvim/vim-connect';

type Props = {
  patientId?: string;
  encounterId?: string;
  onLaunch: (context: Infra.Common.Types.InitialLaunchContext) => void;
  loading: boolean;
};

const TextField = (props: MuiTextFieldProps) => (
  <MuiTextField variant="outlined" size="small" margin="dense" {...props} />
);

export const ContextForm: React.FC<Props> = ({
  patientId: initialPatientId,
  encounterId: initialEncounterId,
  onLaunch,
  loading,
}: Props) => {
  const [patientId, setPatientId] = useState<string>('');
  const [encounterId, setEncounterId] = useState<string>('');
  const [npi, setNpi] = useState<string>('');

  useEffect(() => {
    if (initialPatientId) {
      setPatientId(initialPatientId);
    }
  }, [initialPatientId]);

  useEffect(() => {
    if (initialEncounterId) {
      setEncounterId(initialEncounterId);
    }
  }, [initialEncounterId]);

  const onClick = useCallback(() => {
    const context = {
      patientId,
      encounterId,
      selectedNpi: npi,
    };
    onLaunch(context);
  }, [patientId, encounterId, npi, onLaunch]);

  const isDisabled = useMemo(
    () => !(patientId && encounterId && npi && !loading),
    [patientId, encounterId, npi, loading],
  );

  return (
    <div className="inputs-container">
      <Typography variant="h6" className="title">
        Initial Launch Context
      </Typography>
      <TextField
        id="patient-id-input"
        label="Patient ID"
        value={patientId}
        required
        onChange={(event) => {
          setPatientId(event.target.value);
        }}
      />
      <TextField
        id="encounter-id-input"
        label="Encounter ID"
        value={encounterId}
        required
        onChange={(event) => {
          setEncounterId(event.target.value);
        }}
      />
      <TextField
        id="selected-npi-input"
        label="NPI"
        value={npi}
        required
        onChange={(event) => {
          setNpi(event.target.value);
        }}
      />
      <Button disabled={isDisabled} className="launch-button" variant="contained" onClick={onClick}>
        {loading ? 'Loading...' : 'Launch Smart App'}
      </Button>
    </div>
  );
};
