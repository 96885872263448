import { useCallback, useEffect, useState } from 'react';
import { Entities } from '@getvim/vim-connect';
import { registerVimConnectUIWidget } from '@getvim/utils-vim-connect-communication';
import { EventPayload, OnFhirAppFinishData, WidgetIncomingEventName } from '../types';
import { widgetId } from '../consts';
import { appLogger } from '../common';

export interface UseEventsData {
  patient: Entities.Patient | null;
  encounterId: string | undefined;
  accessToken: string | undefined;
  pubsubId: string | undefined;
  targetId: string | undefined;
  auditEventData: OnFhirAppFinishData | null;
}

export const useEvents = (): UseEventsData => {
  const [patient, setPatient] = useState<Entities.Patient | null>(null);
  const [encounterId, setEncounterId] = useState<string | undefined>();
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [pubsubId, setPubsubId] = useState<string | undefined>();
  const [targetId, setTargetId] = useState<string | undefined>();
  const [auditEventData, setAuditEventData] = useState<OnFhirAppFinishData | null>(null);

  const eventHandler = useCallback(async ({ event, data }: EventPayload) => {
    appLogger.info(`Got event from runtime - ${event}`, { data });
    switch (event) {
      case WidgetIncomingEventName.PatientInContext:
        setPatient(data);
        break;
      case WidgetIncomingEventName.PatientOutOfContext:
        setPatient(null);
        break;
      case WidgetIncomingEventName.EncounterStart:
        setEncounterId(data);
        break;
      case WidgetIncomingEventName.EncounterExit:
        setEncounterId(undefined);
        break;
      case WidgetIncomingEventName.AppConfigChanged:
        setAccessToken(data.accessToken);
        setPubsubId(data.pubsubId);
        setTargetId(data.targetId);
        break;
      case WidgetIncomingEventName.OnFhirAppFinish:
        setAuditEventData(data);
        break;
      default:
        appLogger.warning(`Got unknown event - ${event}`);
        break;
    }
  }, []);

  useEffect(() => {
    registerVimConnectUIWidget(widgetId, eventHandler);
  }, [eventHandler]);

  return { patient, encounterId, accessToken, pubsubId, auditEventData, targetId };
};
