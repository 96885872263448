import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { internalApi } from '../../internal-api/fhir-simulator-bff';
import { GetAuthorizationUrlInput, SMARTApp } from '../../types';
import { appLogger, BaseInternalApi } from '../../common';
import { FHIR_APP_ROUTES } from '../../consts';

export const useLoginRedirect = (): [boolean] => {
  const [hasError, setHasError] = useState(false);
  const { search } = useLocation();

  const { launchIdParam, audienceParam, accessToken } = useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      launchIdParam: params.get('launch'),
      audienceParam: params.get('iss'),
      accessToken: params.get('accessToken'),
    };
  }, [search]);

  const getRedirectUrl = useCallback(
    async (launchId: string, audience: string) => {
      if (accessToken) {
        BaseInternalApi.setAccessToken(accessToken);
      } else {
        appLogger.error('Failed to redirect, missing access token!');
        setHasError(true);
        return;
      }

      const authorizeRouteUrl = new URL(
        FHIR_APP_ROUTES.AUTHORIZE,
        window.location.origin,
      ).toString();
      const input: GetAuthorizationUrlInput = {
        audience,
        launchId,
        smartApp: SMARTApp.fhirSimulator,
        redirectUri: authorizeRouteUrl,
      };

      try {
        const url = await internalApi.fhirSimulatorBff.getAuthorizationUrl(input);
        appLogger.info('Got redirect url', { url, input });

        if (url) {
          window.location.href = url;
        }
      } catch (error) {
        appLogger.error('Failed Getting redirect url!', { error, input });
        setHasError(true);
      }
    },
    [accessToken],
  );

  useEffect(() => {
    if (launchIdParam && audienceParam) {
      getRedirectUrl(launchIdParam, audienceParam);
    }
  }, [launchIdParam, audienceParam, getRedirectUrl]);

  return [hasError];
};
